import { Link } from "gatsby"
import React from "react"
import ReactMarkdown from "react-markdown"
import styles from "./agreement.module.css"
import Layout from "../components/layout"
import SEO from "../components/seo"
import DiagonalArrowIcon from "../components/svg/diagonalArrowIcon"

const CustomLinkComponent = ({ href, children }) => {
  return <Link to={href}>{children[0]}</Link>
}

const CustomCodeComponent = ({ children }) => {
  return (
    <ReactMarkdown
      source={children}
      className={styles.quote}
      renderers={{ link: CustomLinkComponent }}
    />
  )
}

export default function Agreement({ pageContext }) {
  const renderers = {
    inlineCode: CustomCodeComponent,
    link: CustomLinkComponent,
  }

  const agreement = pageContext

  return (
    <Layout
      mainClassName={styles.wrapper}
      pageWrapperClassName={styles.fullPageWrapper}
    >
      <SEO title={`${agreement.name}`} />
      <Link to="/">
        <div className={styles.backLink}>
          <DiagonalArrowIcon className={styles.arrow} />
          <p>about</p>
        </div>
      </Link>
      <ReactMarkdown
        source={agreement.description}
        className={styles.agreementBody}
        renderers={renderers}
      />
    </Layout>
  )
}
